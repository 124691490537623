header {
	padding: 1.5rem 0 5rem;
	opacity: 0;

	@include media-breakpoint-up(sm) {
	  padding: 1.5rem 0;
	}
	
	@include media-breakpoint-up(md) {
	  padding: 2.3rem 0 3.75rem;
	  height: 208px;
	  overflow: hidden;
	}

	.logo {
		font-size: 1.5rem;
		white-space: nowrap;

		@include media-breakpoint-up(md) {
		  font-size: 1rem;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}

		&-container {
			height: 1.5rem;	
			z-index: 99;

			@include media-breakpoint-up(md) {
				height: 1.25rem;
			}

			@include media-breakpoint-up(md) {
				height: 2.375rem;
			}
		}

		@include media-breakpoint-up(sm) {
			font-size: 1.25rem;
		}

		@include media-breakpoint-up(md) {
		  font-size: 2.375rem;
		}
	}

	nav {
		height: 1rem;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: .875rem;
		margin-top: 3.75rem !important;

		@include media-breakpoint-up(md) {
			height: .875rem;
		}

		@include media-breakpoint-down(md) {
			ul {
				height: 1.25rem;
				padding-bottom: 3px;
				padding: 0 1.5rem;
				overflow-x: auto;
				width: 100vw;
				overflow-y: hidden;
				margin-left: -1.5rem;
		    -ms-overflow-style: none;
		    scrollbar-width: none; 
		    scrollbar-height: none; 

		    &::-webkit-scrollbar {
		    	height: 0;
		    	width: 0;
		    	opacity: 0;
		    }
			}
		}


		li {
			margin: 0 .875rem;
			white-space: nowrap;
		  
		  @include media-breakpoint-up(md) {
		  	margin: 0 1.25rem;
		  }

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
				padding-right: 1.25rem;
			}
		}
	}
}