@charset 'UTF-8';
@import url("https://use.typekit.net/jfq2jru.css");
$transition-collapse: height .5s ease-in-out !important;
$spacers: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  7: 28px,
  8: 32px,
  9: 36px,
  10: 40px
);
$grid-gutter-width: 20px;

@import 'node_modules/bootstrap/scss/bootstrap-grid';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'node_modules/bootstrap/scss/helpers/ratio';

@import 'utilities/colors';
@import 'utilities/lh';
@import 'utilities/bg';
@import 'utilities/fs';
@import 'utilities/ls';
@import 'utilities/b';
@import 'utilities/opacity';
@import 'utilities/zIndex';
@import 'utilities/hover';
@import 'utilities/p';
@import 'utilities/fw';

@import 'general/reset';
@import 'general/button';
@import 'general/core';
@import 'general/typography';
@import 'general/scrollbar';
@import 'general/reveal';

@import 'components/footer';
@import 'components/header';
@import 'components/img';
@import 'components/loader';
@import 'components/hamburger';
@import 'components/nav';

@import 'sections/grid';
@import 'sections/single';


.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #19bc8b;
  width: 0%;
  height: 100%;
}

.load-screen1 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #e4cb58;
  width: 100%;
  height: 0%;
}