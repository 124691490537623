@include media-breakpoint-up(md) {
	body,
	.nav .container-fluid {
		&::-webkit-scrollbar {
		  width: 5px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background-color: $white;
			border-left: 1px solid $white;
		}
		 
		/* Handle */
		&::-webkit-scrollbar-thumb {
		  background: $black; 
		}
	}
}


// .c-scrollbar {
// 	opacity: 1;
// 	z-index: 99;

// 	@at-root .has-scroll-scrolling & {
// 		transform: scaleX(2);
// 	}

// 	&:hover {
// 		transform: scaleX(2);
// 	}

// 	.c-scrollbar_thumb {
// 		border-radius: 0;
// 		opacity: 1;
// 		background: $black;
// 		margin: 0;
// 		width: 3px;

// 	}
// }

// html, body {
// 	//overflow: hidden;
// }

// html.has-scroll-smooth {
//   overflow: hidden;
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;  
// }

// .has-scroll-init {
//   overflow: visible;

//   body {
//     overflow: visible;
//     //overflow-x: hidden;
//   }
// }