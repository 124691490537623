// @font-face {
//     font-family: 'Aeonik';
//     src: url('../../fonts/Aeonik-Light.eot');
//     src: url('../../fonts/Aeonik-Light.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/Aeonik-Light.woff2') format('woff2'),
//         url('../../fonts/Aeonik-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
// }

[class^='paragraph'],
[class*=' paragraph'] {

  p, ul, ol {
    margin-bottom: 2.5rem;
    font-family: inherit;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  *:last-child {
    margin-bottom: 0;
  }

  img {
    height: auto;
    width: auto;
  }
}

.list-unstyled {
  list-style-type: none;
}

.paragraph {
  font-size: .875rem;
  letter-spacing: 1px;
  line-height: 1.57;
}

h1 {
}

h2, .h2 {
}

h3 {
}


sup {
  font-size: 60%;
  vertical-align: top;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

i, em {
  font-style: italic;
}

.no-hover {
  &:hover {
    color: currentColor;
  }
}
