.hover-underline,
.hover-rm-underline {

  a {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: currentColor;
      top: 100%;
      left: 0;
      pointer-events: none;
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
    }
  }
	
  .current-menu-item:before,
  a:hover:before,
  a.active:before,
  [aria-current="page"]:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
}

.hover-rm-underline a {
  &:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }

  &:hover:before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
  }
}

.hover-zoom {
  display: block;
  overflow: hidden;

  img {
    will-change: transform;
    transition: transform .8s cubic-bezier(.165,.84,.44,1);
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

}