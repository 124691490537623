.fs-10 {
	font-size: .7rem;
}

.fs-12 {
	font-size: .75rem !important;
}

.fs-14 {
	font-size: .875rem !important;
}

.fs-16 {
	font-size: 1rem !important;
}

.fs-17 {
	font-size: 1.0625rem !important;
}

.fs-18 {
	font-size: 1.125rem !important;
}

.fs-20 {
	font-size: 1.25rem !important;
}

.fs-24 {
	font-size: 1.5rem !important;
}

.fs-m-16 {
  @include media-breakpoint-down(sm) {
  	font-size: 1rem !important;
  }
}

.fs-m-18 {
  @include media-breakpoint-down(sm) {
  	font-size: 1.125rem !important;
  }
}