.grid {

	> a {
		@include media-breakpoint-down(md) {
			margin-top: 0 !important;
		  padding-top: 0 !important;
		  margin-bottom: 2.5rem !important;
		}
	}

	.pt {
		@include media-breakpoint-up(md) {
			padding-top: 12.5rem;

			&.t {
				transform: translateY(-8.5rem);
			}
		}
	}
}

.anim {
	opacity: 0;
}

.post-item {
	//border: .5px solid white;
	display: block;
	overflow: hidden;
	position: relative;
	border: 12px solid white;

	// @at-root .home & {
	// 	@include media-breakpoint-down(sm) {
	// 		border: none;
	// 	}
	// }

	@include media-breakpoint-up(md) {
		border: 20px solid white;
	}

  .ratio:after {
		content: '';
		background: #d0d1cc;
		left: 0;
		top: 0;
		right: 0;
		height: 100%;
		position: absolute;
		z-index: 1;
		opacity: 0;
		//transition: opacity .5s ease-in-out;
	}

	.post-item-hover {
		opacity: 0;
		text-align: center;
		line-height: 1.1;
		left: 50%;
		top: 52%;
		z-index: 4;
		font-size: 1.5rem;
		text-transform: uppercase;
		color: $white;
		position: absolute;
		transform: translate(-50%, -50%);
		//transition: all .5s ease-in-out;

		@include media-breakpoint-up(sm) {
			font-size: 2.25rem;
		}
	}

	&:hover {
		.ratio:after {
			opacity: 1;
		}

		.post-item-hover {
			opacity: 1;
		  top: 50%;
		}
	}
}