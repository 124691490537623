.nav-bg {
	position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: $white;
  z-index: 5;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 4;
  pointer-events: none;

  &-open {
  	overflow: hidden;

  	.page {
  		pointer-events: none;
  	}
  }

  &.is-open {
    pointer-events: all;
  }

  ul {
  	display: flex;
  	//height: 100%;
  	flex-direction: column;
  	padding: 6.25rem 1.5rem 1.75rem;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: .875rem;
		//position: relative;
		z-index: 999;
	  overflow: auto;
	  position: absolute;
	  min-height: 100%;
	  top: 0;
	  left: 0;
	  height: auto;
	  bottom: 0;
	  -webkit-overflow-scrolling: touch;

		li {
			position: relative;
			margin-bottom: 1.25rem;

			// img {
			// 	object-fit: cover;
			// 	position: absolute;
			// 	left: 0;
			// 	top: 0;
			// 	width: 100%;
			// 	height: 100%;
			// }
		}
  }
}