.zIndex30 {
	z-index: 30;
}

.zIndex2 {
	z-index: 2;
}

.zIndex5 {
	z-index: 5;
}

.zIndex10 {
	z-index: 10;
}