.react-reveal {
	pointer-events: none;
	overflow: hidden;

	.ratio {
		will-change: transform;
		transition: transform .5s cubic-bezier(0.4, 0, 0, 1.1);

		&:after {
		  content: '';
			height: 100%;
			top: 0;
			left: 0;
			width: 100%;
			background-color: $white;
			position: absolute;
			z-index: 1;
			will-change: transform;
			transform-origin: 0 0;
			transition: transform 1s cubic-bezier(0.4, 0, 0, 1.1) .4s;
		}

		img {
			transform: scale(1.1);
			will-change: transform;
			transition: transform 1.5s cubic-bezier(0.4, 0, 0, 1.1) .8s;
		}
	}

	&.is-visible {
		pointer-events: all;

		.ratio {
			&:after {
				transform: scaleY(0);
			}
			
			img {
				transform: scale(1);
			}
		}
	}
}