.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: stretch;
  overflow: hidden;
  z-index: 10;
  font-size: 1.5rem;
  pointer-events: none;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }
 
  &-inner {
    position: relative;
    background-color: $beige;
    width: 100%;
    height: 100vh;
    max-height: stretch;
    overflow: hidden;
    white-space: nowrap;
  }

  .row {
    justify-content: space-between;
    flex-direction: column;
  }
}