.single {
	h1 {
		padding-bottom: 2.5rem;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(md) {
		.position-sticky {
			top: 1.875rem;
			z-index: 3;
			//transform: translateY(100%);
		}
	}
}

.sticky {
	.react-reveal {
		position: sticky;
		top: 1.875rem;
		z-index: 3;
	}
}

.next-link {
	//height: 1.25rem;
	position: relative;

	.position-absolute {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 5;
		pointer-events: none;
	}

	&:hover {
		//height: auto;

		.position-absolute {
			pointer-events: all;
		}
	}

	img {
		width: 150px;
		height: 185px;
		object-fit: contain;
		object-position: top;
	}
}