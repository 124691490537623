.bg-white {
	background-color: $white;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-black {
	background-color: $black !important;

	::-moz-selection {
	  color: $black;
	  background: $white;
	}

	::selection {
	  color: $black;
	  background: $white;
	}
}

.bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
