* {
 box-sizing: border-box;
 -ms-content-zooming: none;
 -ms-scroll-chaining: none;
   //-webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  // backface-visibility: hidden;
   -moz-osx-font-smoothing: grayscale;
   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
   -webkit-appearance: none;
   -moz-appearance: none;
  font-family: neue-haas-grotesk-display, sans-serif;
  //font-family: 'Aeonik';
}
::-moz-selection {
 color: $white;
 background: $black;
}
::selection {
 color: $white;
 background: $black;
}

html, body {
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  color: $black;
  line-height: 1;
  font-family: neue-haas-grotesk-display, sans-serif;
  //font-family: 'Aeonik';
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  min-height: 101vh;
}



a {
 cursor: pointer;
 font-family: inherit;
 font-size: inherit;
 font-weight: inherit;
 font-style: inherit;
 color: inherit;
 line-height: inherit;
 letter-spacing: inherit;
 text-decoration: none;
 //transition: all .5s cubic-bezier(0.4, 0, 0.1, 1);

   &:hover,
   &:focus {
    text-decoration: none;
    color: currentColor;
  }
}

img,
svg {
  max-width: 100%;
  display: block;
  height: auto;

  &.lazyload {
    opacity: 0;
    transition: all .5s cubic-bezier(0.4, 0, 0.1, 1);

    &.loaded {
      opacity: 1;
    }
  }
}

textarea {
 border-radius: 0;
 overflow: hidden;
 resize: none;
}

input {  
  font-size: inherit;
  border-radius: 0;
  color: $black;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

button, input[type="submit"] {
 cursor: pointer;
 border-radius: 0;
}

button,
input,
select,
textarea {
  font: inherit;
}

em, i {
  font-style: italic;
}


.container-fluid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  
  @include media-breakpoint-up(sm) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.is-disabled {
  opacity: .3;
  pointer-events: none;
}

.error404 {
  height: 100vh;
}

.page {
  position: relative;
}

.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.mt-n5 {
  margin-top: -.75rem;

  @include media-breakpoint-up(md) {
    margin-top: -1.25rem;
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  z-index: 99;
}