.hamburger {
	outline: none;
  border: none;
  -webkit-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 1.625rem;
  right: 1.3125rem;
  width: 1.5rem;
  padding: 0;
  height: 1.5rem;
  z-index: 9999;
  background: transparent;
  pointer-events: all;

  svg {
  	margin-left: auto;
  }
}