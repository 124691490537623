.img-fit {
	object-fit: cover;
}

[style*="--aspect-ratio"]::before {
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

figcaption {
	margin-top: .5rem;
	line-height: 1.4;
	font-size: .75rem;
  //font-family: 'Roboto Mono', monospace;
}

section > {
	figure {
		figcaption {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}


.ratio {
	overflow: hidden;
	width: calc(100% + 1px);
  //transition: transform .5s cubic-bezier(0.4, 0, 0, 1.1);
}

img.lazy {
	opacity: 0;
	transition: opacity .3s cubic-bezier(0.4, 0, 0, 1.1), transform .8s cubic-bezier(.165,.84,.44,1);
}