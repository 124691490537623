.lh-1 {
	line-height: 1;
}

.lh-1-1 {
	line-height: 1.1;
}

.lh-1-2 {
	line-height: 1.2;
}

.lh-1-3 {
	line-height: 1.3;
}